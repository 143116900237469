import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Text,
  Button,
  Stack,
  Flex,
  Heading,
  Progress,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import Loading from '../components/Loading';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import UpgradePrompt from '../components/payments/UpgradeCard';
import SelectDomain from '../components/domains/selectDomain';
import SelectTOV from '../components/TOV/SelectTOV';

const ENV = process.env.NODE_ENV;
const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai';


export default function Account() {
  const activeUser = useSelector((state) => state.user.activeUser);
  const [creditInfo, setCreditInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);

  const { innerWidth: width } = window;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const fetchCreditInfo = async () => {
    try {
      const response = await axios.get(`${api_url}/creditsInfo`, {
        withCredentials: true
      });

      setCreditInfo(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching credit info:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCreditInfo();
  }, [activeUser]);

  if (width <= 900) {
    return (
      <Flex justify={'center'} align={'center'} p={'20px'}>
        <Heading size={'sm'}>
          Sorry we don't currently support screen sizes below 900px
        </Heading>
      </Flex>
    );
  }

  const emailLink = "mailto:andrew@veolabs.ai?subject=Feedback";

  const handleClick = () => {
    window.location.href = emailLink;
  };

  const handleCustomerPortal = async () => {
    try {
      const response = await axios.post(`${api_url}/create-customer-portal-session`, {
        customerId: activeUser.stripeId,
      }, {
        withCredentials: true
      });

      const { url } = response.data;
      window.open(url, '_blank');
    } catch (error) {
        console.error('Error redirecting to customer portal:', error);
        toast({
          title: "Error",
          description: "Unable to redirect to customer portal",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
    }
  };

  const handleUpgradeSubscription = () => {
    onOpen(); // Show the Modal containing the UpgradePrompt component
  };

  const handleUpgradeSuccess = () => {
    // Update your state if necessary
    toast({
      title: "Success",
      description: "Your subscription has been upgraded!",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    onClose();
    fetchCreditInfo();
  };

  const handleUpgradeError = (error) => {
    toast({
      title: "Error",
      description: `Failed to upgrade subscription: ${error}`,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  let unlimitedTrial = activeUser.subscription.split('-')[1] === 'unlimited';
  if(creditInfo?.subscriptionStatus === 'active'){unlimitedTrial = false};

  return (
    <Container pt='80px'>
      <Stack as={Box} textAlign={'center'}>
        {loading ? (
          <Flex align='center' justify='center' mt='-300px'>
            <Loading />
          </Flex>
        ) : (
          <>
            <Text color={'gray.500'}>
              <strong>Email/Username: </strong>{activeUser.username}<br/><br/>
              {creditInfo && (
                <>
                  <strong>Plan Name: </strong>{creditInfo.planName}<br/><br/>
                  <strong>Monthly Credits: </strong>{creditInfo.monthlyCredits}<br/><br/>
                  <strong>Used Credits: </strong>{creditInfo.usedCredits}<br/><br/>
                  <strong>Remaining Credits: </strong>{creditInfo.remainingCredits}<br/><br/>
                  <Progress value={(creditInfo.usedCredits / creditInfo.monthlyCredits) * 100} colorScheme='blue' size='lg' borderRadius='lg' hasStripe isAnimated />
                  <br/><br/>
                  {unlimitedTrial ? null : (
                    activeUser.stripeId && creditInfo?.subscriptionStatus === 'active' ? (
                        <>
                        <strong>Credits Refresh On: </strong> {new Date(creditInfo.nextRenewalDate).toLocaleDateString()}<br/><br/>
                        </>
                    ) : (
                        <>
                        <strong>Your trial period ends in: </strong> {
                            creditInfo.subscriptionStatus === 'expired' 
                            ? 'Trial Expired' 
                            : formatDistanceToNow(new Date(creditInfo.nextRenewalDate))}<br/><br/>
                        </>
                    )
                    )}
                  <strong>Subscription Status: </strong> {unlimitedTrial ? 'active trial' : creditInfo.subscriptionStatus}<br/><br/>
                  <Stack direction={'row'} spacing={4} justify='center' mt={'10px'}>
                    <Button variant="solid" colorScheme='teal' onClick={handleUpgradeSubscription}>Upgrade Subscription</Button>
                  </Stack>
                </>
              )}
            </Text>
            <Flex direction={'column'}>
              <Text color={'gray.500'} pt={'40px'}>
                Manage your stored domains and custom TOVs
              </Text>
              <Flex direction={'row'} gap={4} align={'center'} justify={'center'} alignSelf={'center'} position={'relative'}>
                <SelectDomain hideSelect={true} />
                <Flex pt={'19px'}><SelectTOV hideSelect={true} /></Flex>
              </Flex>
            </Flex>
            {creditInfo?.subscriptionStatus === 'active' ? (
              <>
                <Text color={'gray.500'} pt={'40px'}>
                  Please let us know if you have any questions / feedback. Visit customer portal to update payment details, end your plan or download invoices.
                </Text>
                <Flex direction={'row'} gap={4} align={'center'} alignSelf={'center'} position={'relative'} pb={'100px'}>
                  <Button width='170px' mt='10px' variant="solid" colorScheme='blue' onClick={handleClick}>
                    Contact
                  </Button>
                  <Button width='170px' mt='10px' variant="outline" colorScheme='blue' onClick={handleCustomerPortal}>
                    Customer Portal
                  </Button>
                </Flex>
              </>
            ) : (
              <>
                <Text color={'gray.500'} pt={'40px'}>
                  Please let us know if you have any questions / feedback.
                </Text>
                <Flex direction={'row'} gap={4} align={'center'} alignSelf={'center'} position={'relative'} pb={'100px'}>
                  <Button width='170px' mt='10px' variant="solid" colorScheme='blue' onClick={handleClick}>
                    Contact
                  </Button>
                </Flex>
              </>
            )}
          </>
        )}
      </Stack>

      {/* UpgradePrompt Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Upgrade Subscription</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody>
            <UpgradePrompt onSuccess={handleUpgradeSuccess} onError={handleUpgradeError} />
          </ModalBody>
          {/* <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </Container>
  );
}