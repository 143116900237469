import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    content: "",
    VeoWritercontent: "",
    veoWriterActions: [],
    veoWriterOptions: {
        model: 'GPT-4o-Mini',
        targetKeyword: '',
        kwMethod: 'AI-Powered',
        kwList: '',
        length: 4,  
        TOV: 'SEO Optimised',
        language: 'English',
        targetCountry: 'USA',
        pov: 'Second Person (You, Your, Yours)',
        useRealTimeSearch: false,
        externalLinks: false,
        internalLinks: false,
        domain: '',
        embedYoutubeVideo: false,
        useOutlineEditor: false,
        includeFAQ: false,
        includeKeyTakeaways: false,
        urls: [],
        customOutline: [],
        TitleInstructions: '',
        disableIntro: false,
        disableConclusion: false
    },
    veoWriterActionsLoading: false,
    copyPrompt: "",
    imagePrompt: "",
    audioPrompt: "",
    base64: "",
    audioUri: "",
    audioDownloadBlob: [],
    isLoading: false,
    isLoadingVEOWriter: false,
    isThinking: false,
    conversation: [],
    conversationId: '',
    transcription: '',
    conversationHistory: ['no-history'],
    copyHistory: ['no-history'],
    imageHistory: ['no-history'],
    voiceHistory: ['no-history'],
    agentHistory: ['no-history'],
    voicesLoaded: false,
    voices: [],
    selectedVoice: {},
    activeTasks: {}
}

export const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        update: (state, action) => {
            state.content = action.payload;
        },
        updateActiveTasks: (state, action) => {
            const { operation, key, value } = action.payload;
      
            switch (operation) {
              case 'addOrUpdate':
                state.activeTasks[key] = value;
                break;
      
              case 'remove':
                delete state.activeTasks[key];
                break;
      
              default:
                break;
            }
        },
        updateVeoWritercontent: (state, action) => {
            if(action.payload.action === 'concat'){
                state.VeoWritercontent += action.payload.content;
            };
            if(action.payload.action === 'overwrite'){
                state.VeoWritercontent = action.payload.content;
            }
            
        },
        updateVeoWriterActions: (state, action) => {
            if(action.payload.action === 'concat' && action.payload.content !== ''){
                state.veoWriterActions.push(action.payload.content);
            };
            if(action.payload.action === 'overwrite'){
                state.veoWriterActions = action.payload.content;
            }
        },
        updateVeoWriterOptions: (state, action) => {
            state.veoWriterOptions = action.payload;
        },
        updateVeoWriterActionsLoading: (state, action) => {
            state.veoWriterActionsLoading = action.payload;
        },
        updateTranscription: (state, action) => {
            state.transcription = action.payload;
        },
        updateCopyPrompt: (state, action) => {
            state.copyPrompt = action.payload;
        },
        updateImagePrompt: (state, action) => {
            state.imagePrompt = action.payload;
        },
        updateAudioPrompt: (state, action) => {
            state.audioPrompt = action.payload;
        },
        updateBase64: (state, action) => {
            state.base64 = action.payload;
        },
        updateAudioUri: (state, action) => {
            state.audioUri = action.payload;
        },
        updateAudioDownloadBlob: (state, action) => {
            state.audioDownloadBlob[0] = action.payload;
        },
        toggleLoading: (state, action) => {
            state.isLoading = !state.isLoading;
        },
        updateIsLoadingVEOWriter: (state, action) => {
            state.isLoadingVEOWriter = action.payload;
        },
        switchThinking: (state, action) => {
            state.isThinking = action.payload;
        },
        toggleVoiceIsLoaded: (state, action) => {
            state.voicesLoaded = !state.voicesLoaded;
        },
        updateConversation: (state, action) => {
            if(action.payload[0] === 1){
                state.conversation = [...state.conversation, action.payload[1]]
            } else if(action.payload[0] === 3){
                state.conversation = []
            } else if(action.payload[0] === 4){
                state.conversation = action.payload[1]
            }else {
                if(state.conversation[state.conversation.length -1].role === 'user'){
                    state.conversation[state.conversation.length] = action.payload[1];
                } else {
                    state.conversation[state.conversation.length -1] = action.payload[1];
                }
            }
        },
        updateConversationId: (state, action) => {
            state.conversationId = action.payload
        },
        updateConversationHistory: (state, action) => {
            state.conversationHistory = action.payload
        },
        updateVoiceHistory: (state, action) => {
            state.voiceHistory = action.payload
        },
        updateAgentHistory: (state, action) => {
            state.agentHistory = action.payload
        },
        updateImageHistory: (state, action) => {
            if(action.payload[0] === 0){
                let newState = state.imageHistory.map(img => img._id === action.payload[1]._id ? action.payload[1] : img);
                state.imageHistory = newState;
            }
            if(action.payload[0] === 1){
                state.imageHistory = action.payload[1];
            }
            if(action.payload[0] === 2){
                let newState = state.imageHistory.filter(img => img._id !== action.payload[1]);
                state.imageHistory = newState;
            }
        },
        updateCopyHistory: (state, action) => {
            state.copyHistory = action.payload
        },
        updateVoices: (state, action) => {
            state.voices = [...state.voices, action.payload]
        },
        updateSelectedVoice: (state, action) => {
            state.selectedVoice = action.payload
        }
    }
});

export const { 
    update,
    updateCopyPrompt,
    updateBase64, 
    updateAudioUri, 
    updateAudioPrompt,
    updateAudioDownloadBlob, 
    toggleLoading, 
    updateIsLoadingVEOWriter,
    switchThinking, 
    toggleVoiceIsLoaded, 
    updateConversation, 
    updateVoiceHistory,
    updateConversationId,
    updateCopyId,
    updateConversationHistory,
    updateCopyHistory,
    updateImageHistory,
    updateAgentHistory,
    updateVoices,
    updateImagePrompt,
    updateSelectedVoice,
    updateTranscription,
    updateVeoWritercontent,
    updateVeoWriterActions,
    updateVeoWriterActionsLoading,
    updateVeoWriterOptions,
    updateActiveTasks
} = contentSlice.actions;

export default contentSlice.reducer;