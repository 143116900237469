import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { 
    Card, 
    Image,
    CardBody,
    Stack,
    Heading,
    CardFooter,
    Button,
    Flex,
    Text,
    Badge,
} from '@chakra-ui/react';
import AgentDetail from '../components/AgentDetail';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveAgents } from "../data/agents";
import { updateAgentList, loaded } from '../store/agentSlice';
import Loading from '../components/Loading';

function Agents(){

  const agentList = useSelector((state) => state.agents.agentList);
  const agentListLoaded = useSelector((state) => state.agents.loadedAgentList);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { innerWidth: width} = window;

  useEffect(() => {
    if (!agentListLoaded) {
        retrieveAgents().then(agentList => {
            dispatch(updateAgentList(agentList));
            dispatch(loaded());
        });
    }
  }, [agentListLoaded, dispatch]);

  if (!agentListLoaded) {
    return <Loading />;
  }

  if(width <= 900){
    return (
        <Flex
            justify={'center'}
            align={'center'}
            p={'20px'}
            >
            <Heading size={'sm'}>
                Sorry we don't currently support screen sizes below 900px
            </Heading>
        </Flex>
    )
  }

  const renderedModels = agentList.map(agent => {

    const renderedAbilities = agent.abilities.map(ability => {
        return <Badge variant='solid' mt='20px' mr='10px' key={ability}>{ability}</Badge>
    });

    let generateLink = `/agents/${agent.path}`;
    
    const Chat = () => {
        if(agent.abilities.includes('Chat')){
            return (
                <Button 
                    variant='solid'
                    colorScheme='blue' 
                    mr='15px'
                    w='90px'
                    size='sm'
                    onClick={() => {navigate('/generate/chat')}}
                >
                    Chat
                </Button>
            )
        } else {
            return null;
        }
    };

    return <Card
        key={agent.title}
        direction={{ base: 'column', sm: 'row' }}
        overflow='hidden'
        variant='outline'
        min-height='250px'
        width='650px'
        margin='30px'
        boxShadow='md'
    >
        <Flex w={'350px'}>
            <Image
                objectFit='cover'
                maxW={{ base: '100%', sm: '200px' }}
                src={`/images/${agent.icon}`}
                alt={agent.title}
            />
        </Flex>
        
        <Stack>
            <CardBody>
                <Heading size='sm'>{agent.title}</Heading>
                <Text py='2' fontSize='sm'>
                {agent.description}
                </Text>
                {renderedAbilities}
            </CardBody>
            <CardFooter justify='right' pt='0px'>
                <Button variant='solid' colorScheme='blue' mr='15px' w='90px' size='sm' onClick={() => {navigate(generateLink)}}>
                    Generate
                </Button>
                <Chat />
                <AgentDetail agent={agent}/>
            </CardFooter>
        </Stack>
    </Card>
  });

  return (
    <Flex direction="column" sx={{userSelect: 'none'}}>
        <Flex wrap='wrap' justify='center' pt='35px'>
            {renderedModels}
        </Flex>
    </Flex>
  )
}

export default Agents;