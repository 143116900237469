import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  VStack,
  Text,
  HStack,
  Spacer,
  Spinner,
  useToast,
  Heading,
  Image,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  InputGroup,
  InputLeftAddon,
  Link
} from "@chakra-ui/react";
import { ExternalLinkIcon, DeleteIcon, AddIcon } from "@chakra-ui/icons";

const PublishManager = ({ connections, addConnection, content, deleteConnection, fetchConnections, publish, disable }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newConnection, setNewConnection] = useState({
    username: '',
    url: '',
    name: '',
    password: '',
    platform: '',
    userId: '',
  });
  const [isAdding, setIsAdding] = useState(true); // Initialize based on connections
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [addingConnection, setAddingConnection] = useState(false); // Add a loading state for adding connection
  const [deletingConnection, setDeletingConnection] = useState(null);
  const [publishingDomainId, setPublishingDomainId] = useState(null);
  const [helpVisible, setHelpVisible] = useState({ wordpress: false, webflow: false, shopify: false, ghost: false });
  const toast = useToast();

  const handleAddConnection = async () => {
    setAddingConnection(true);
    try {
      const response = await addConnection(newConnection);

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'New connection has been created',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setIsAdding(false);
        setNewConnection({
          username: '',
          url: '',
          name: '',
          password: '',
          platform: '',
          userId: '',
        });
        fetchConnections();
      } else {
        const result = await response.json();
        toast({
          title: 'Error',
          description: result.message || 'Something went wrong',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Internal Server Error',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setAddingConnection(false);
    }
  };

  const handlePublish = async (connectionId) => {
    setPublishingDomainId(connectionId);
    try {
      const response = await publish({ content, connectionId });

      if (response.status === 200) {
        const result = await response.json();
        toast({
          title: 'Success',
          description: 'Content has been published to draft',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        const result = await response.json();
        toast({
          title: 'Error',
          description: result.message || 'Something went wrong',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Internal Server Error',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setPublishingDomainId(null);
    }
  };

  const handleDeleteConnection = async (id) => {
    setDeletingConnection(id);
    try {
      const response = await deleteConnection({ _id: id });

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Connection has been deleted',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchConnections();
      } else {
        const result = await response.json();
        toast({
          title: 'Error',
          description: result.message || 'Something went wrong',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Internal Server Error',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setDeletingConnection(null);
    }
  };
  let disableButton = content.length ? false : true;
  return (
    <Box>
      <Flex alignItems="center">
        <Button
          onClick={() => { 
            onOpen(); // Call onOpen function correctly
            if (connections.length > 0) { 
              setIsAdding(false);
            } else { 
              setIsAdding(true);
            }
          }}
          variant="solid"
          colorScheme="blue"
          pr={8}
          leftIcon={<ExternalLinkIcon />}
          isDisabled={disableButton || disable} 
        >
          Publish
        </Button>
      </Flex>
      <PublishPopup
        setNewConnection={setNewConnection}
        newConnection={newConnection}
        publish={handlePublish}
        isOpen={isOpen}
        onClose={onClose}
        connections={connections}
        handleDeleteConnection={handleDeleteConnection}
        isAdding={isAdding}
        setIsAdding={setIsAdding}
        handleAddConnection={handleAddConnection}
        setFile={setFile}
        file={file}
        isLoading={isLoading}
        deletingConnection={deletingConnection}
        publishingDomainId={publishingDomainId}
        content={content}
        addingConnection={addingConnection}
        helpVisible={helpVisible}
        setHelpVisible={setHelpVisible}
      />
    </Box>
  );
};

const PublishPopup = ({
  setNewConnection,
  newConnection,
  handleAddConnection,
  isOpen,
  onClose,
  connections,
  handleDeleteConnection,
  deletingConnection,
  isAdding,
  setIsAdding,
  publish,
  isLoading,
  publishingDomainId,
  addingConnection, // Add loading state for adding connection
  helpVisible,
  setHelpVisible,
}) => {
  const toast = useToast();

  const getImageSrc = (platform) => {
    switch (platform) {
      case 'wordpress':
        return "/images/wp_logo.svg.png";
      case 'webflow':
        return "/images/webflow.svg";
      case 'shopify':
        return "/images/shopify.webp";
      case 'ghost':
        return "/images/ghost.png";
      default:
        return "/images/default_logo.png";
    }
  };

  const newConnectionHandleChange = (updatedFieldData) => {
    setNewConnection((prevState) => ({
      ...prevState,
      ...updatedFieldData,
    }));
  };

  const resetNewConnection = () => {
    setNewConnection({
      username: '',
      url: '',
      name: '',
      password: '',
      platform: '',
      userId: '',
    });
  };

  const toggleHelpContent = (platform) => {
    setHelpVisible((prev) => ({ ...prev, [platform]: !prev[platform] }));
  };

  // Helper function to get the help content for each tab
  const getHelpContent = (platform) => {
    switch (platform) {
      case 'wordpress':
        return <Flex padding={'10px'} bgColor={"gray.100"} direction={'column'} gap={'10px'}>
                <Text fontSize='xs'>Link to a user holding either an Author, Editor, or Admin role. We suggest choosing an Editor or Admin role for optimal functionality, as the Author role might cause some features to not work correctly.</Text>
                <Text fontSize='xs'>Ensure you use an Application Password, not a regular password. You can generate an Application Password on the Profile page or while editing a User in the WordPress Admin. <Link color='teal.500' href='https://www.youtube.com/watch?v=bsz6hb1EUMY' isExternal>(Watch a tutorial here)</Link></Text>
                <Text fontSize='xs'>When specifying the domain, include only the domain name like "google.com" without "https://google.com/".</Text>
              </Flex>;
      case 'shopify':
        return <Flex padding={'10px'} bgColor={"gray.100"} direction={'column'} gap={'10px'}>
                  <Text fontSize='xs'>Follow these steps to obtain your Shopify store name and access token:</Text>
                  
                  <Text fontSize='xs'>To find your Store Name:</Text>
                  <Text fontSize='xs'>  - Navigate to your <Text as='b' fontSize='xs'>Shopify admin dashboard.</Text></Text>
                  <Text fontSize='xs'>  - Click on <Text as='b' fontSize='xs'>Settings</Text> at the bottom left.</Text>
                  <Text fontSize='xs'>  - Note the <Text as='b' fontSize='xs'>original store name</Text> assigned by Shopify. For instance, if the store name is <Text as='b' fontSize='xs'>a4fd3.myshopify.com</Text>, enter <Text as='b' fontSize='xs'>a4fd3</Text> below.</Text>
                  
                  <Text fontSize='xs'>To get your Blog ID:</Text>
                  <Text fontSize='xs'>  - From the Shopify admin dashboard, click <Text as='b' fontSize='xs'>Online Store.</Text></Text>
                  <Text fontSize='xs'>  - Click on <Text as='b' fontSize='xs'>Blog posts.</Text></Text>
                  <Text fontSize='xs'>  - Click on <Text as='b' fontSize='xs'>Manage blogs</Text> near the upper right.</Text>
                  <Text fontSize='xs'>  - Select an existing blog or create a new one.</Text>
                  <Text fontSize='xs'>  - Copy the last 12 digits from the URL. This is your <Text as='b' fontSize='xs'>Blog ID</Text> and should look like: 107388361993.</Text>
                  
                  <Text fontSize='xs'>To create the Shopify Access Token:</Text>
                  <Text fontSize='xs'>  - Head to the <Text as='b' fontSize='xs'>Settings page.</Text></Text>
                  <Text fontSize='xs'>  - In the sidebar, click on <Text as='b' fontSize='xs'>Apps and sales channels.</Text></Text>
                  <Text fontSize='xs'>  - Click on <Text as='b' fontSize='xs'>Develop Apps</Text> at the top.</Text>
                  <Text fontSize='xs'>  - Enable <Text as='b' fontSize='xs'>Allow custom app development.</Text></Text>
                  <Text fontSize='xs'>  - Click <Text as='b' fontSize='xs'>Create App</Text> and name it something like "Veolabs".</Text>
                  <Text fontSize='xs'>  - Click on <Text as='b' fontSize='xs'>Configure Admin API scopes</Text> and select the <Text as='b' fontSize='xs'>write_content</Text> and <Text as='b' fontSize='xs'>read_content</Text> permissions, then save.</Text>
                  <Text fontSize='xs'>  - Press <Text as='b' fontSize='xs'>Install</Text> and copy the access token that starts with "shpat_". Paste this token below.</Text>
                </Flex>;
      case 'webflow':
        return <Flex padding={'10px'} bgColor={"gray.100"} direction={'column'} gap={'10px'}>
                <Text fontSize='xs'>Follow these steps to obtain your Webflow Collection ID and API Token:</Text>
                
                <Text fontSize='xs'>To get your Collection ID:</Text>
                <Text fontSize='xs'>  - In your site's Designer, click the <Text as='b' fontSize='xs'>CMS</Text> icon in the sidebar.</Text>
                <Text fontSize='xs'>  - Click the <Text as='b' fontSize='xs'>Edit</Text> icon next to the Collection you want to use (e.g., Blog Posts) and copy the <Text as='b' fontSize='xs'>Collection ID</Text>. If no collection exists, you'll need to create one first.</Text>
                
                <Text fontSize='xs'>To create the API Token:</Text>
                <Text fontSize='xs'>  - Navigate to <Text as='b' fontSize='xs'>Site settings {'>'} Apps & Integrations tab {'>'} API access</Text></Text>
                <Text fontSize='xs'>  - Click <Text as='b' fontSize='xs'>Generate API token</Text>.</Text>
                <Text fontSize='xs'>  - Enter a name like "Veolabs" and select the <Text as='b' fontSize='xs'>Read and write</Text> permission for <Text as='b' fontSize='xs'>CMS</Text>.</Text>
                <Text fontSize='xs'>  - Click <Text as='b' fontSize='xs'>Generate token</Text>.</Text>
                <Text fontSize='xs'>  - Copy the API token and paste it in the form below.</Text>
              </Flex>;
      case 'ghost':
        return <Flex padding={'10px'} bgColor={"gray.100"} direction={'column'} gap={'10px'}>
                <Text fontSize='xs'>Follow these steps to get your Ghost Admin Domain and Admin API Key:</Text>
              
                <Text fontSize='xs'>To get your Admin Domain:</Text>
                <Text fontSize='xs'>  - In your site's Dashboard, click on <Text as='b' fontSize='xs'>Ghost(Pro)</Text>.</Text>
                <Text fontSize='xs'>  - On the Ghost(Pro) page, click on <Text as='b' fontSize='xs'>Domain</Text> to find your domain.</Text>
              
                <Text fontSize='xs'>Next, obtain your Admin API Key:</Text>
                <Text fontSize='xs'>  - In your site's Dashboard, click the Settings icon at the lower left.</Text>
                <Text fontSize='xs'>  - Scroll to Advanced settings and click on <Text as='b' fontSize='xs'>Integrations</Text>.</Text>
                <Text fontSize='xs'>  - Click on <Text as='b' fontSize='xs'>Custom</Text>.</Text>
                <Text fontSize='xs'>  - Click <Text as='b' fontSize='xs'>Add custom integration</Text> and enter a name like "Veolabs".</Text>
                <Text fontSize='xs'>  - Customize the <Text as='b' fontSize='xs'>custom integration</Text> settings as desired, then click <Text as='b' fontSize='xs'>Save & close</Text>.</Text>
                <Text fontSize='xs'>  - Click on the custom integration you just created.</Text>
                <Text fontSize='xs'>  - Copy the value under <Text as='b' fontSize='xs'>Admin API key</Text> and paste it below.</Text>
              </Flex>;
      default:
        return <Flex padding={'10px'} bgColor={"gray.100"} direction={'column'} gap={'10px'}>
                <Text fontSize='xs'>No instructions available.</Text>
              </Flex>;
    }
  };

  const isFormComplete = () => {
    const { url, username, password, platform } = newConnection;
    if (platform === 'ghost') {
      return url && password && platform;
    }
    return url && username && password && platform;
  };

  return (
    <Modal isOpen={isOpen} scrollBehavior={'inside'} onClose={() => { onClose(); setIsAdding(false); resetNewConnection(); }} >
      <ModalOverlay />
      <ModalContent minHeight="550px" maxHeight="550px" maxW={'650px'} width={'auto'} minW="650px">
        <ModalHeader>{isAdding ? "Add a New Connection" : "Publish Draft"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Flex align="center" justify="center" height="100%" direction="column" gap="5px" marginTop="20px">
              <Heading>Establishing connection...</Heading>
              <Text marginBottom="30px">This can take several minutes.</Text>
              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
            </Flex>
          ) : isAdding ? (
            <Tabs variant="enclosed" onChange={() => resetNewConnection()}>
              <TabList>
                <Tab>
                  <Image
                    src="/images/wp_logo.svg.png"
                    alt="Logo"
                    boxSize="20px"
                    objectFit="cover"
                    mr={1}
                  />
                  WordPress
                </Tab>
                <Tab>
                  <Image
                    src="/images/webflow.svg"
                    alt="Logo"
                    boxSize="20px"
                    objectFit="cover"
                    mr={1}
                  />
                  Webflow
                </Tab>
                <Tab>
                  <Image
                    src="/images/shopify.webp"
                    alt="Logo"
                    boxSize="20px"
                    objectFit="cover"
                    mr={1}
                  />
                  Shopify
                </Tab>
                <Tab>
                  <Image
                    src="/images/ghost.png"
                    alt="Logo"
                    boxSize="20px"
                    objectFit="cover"
                    mr={1}
                  />
                  Ghost
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Button mt="4" onClick={() => toggleHelpContent('wordpress')}>
                    {helpVisible.wordpress ? 'Hide Instructions' : 'Show Instructions'}
                  </Button>
                  {helpVisible.wordpress && (
                    <Text whiteSpace="pre-wrap" mt="4">
                      {getHelpContent('wordpress')}
                    </Text>
                  )}
                  <Flex width="100%" justify="center" direction="column" marginTop="20px" gap="20px">
                  
                    <Flex direction="column">
                      <Text color="#505050" width="100%" as="b">
                        WordPress Site Domain
                      </Text>
                      <InputGroup>
                        <InputLeftAddon>https://</InputLeftAddon>
                        <Input
                          placeholder="your-wordpress-site.com"
                          value={newConnection.url}
                          onChange={(e) => newConnectionHandleChange({ url: e.target.value, platform: 'wordpress' })}
                        />
                      </InputGroup>
                    </Flex>
                    <Flex direction="column">
                      <Text color="#505050" width="100%" as="b">
                        WordPress Username
                      </Text>
                      <Input
                        value={newConnection.username}
                        onChange={(e) => newConnectionHandleChange({ username: e.target.value })}
                      />
                    </Flex>
                    <Flex direction="column">
                      <Text color="#505050" width="100%" as="b">
                        WordPress Application Password
                      </Text>
                      <Input
                        value={newConnection.password}
                        onChange={(e) => newConnectionHandleChange({ password: e.target.value })}
                      />
                    </Flex>
                    
                  </Flex>
                </TabPanel>

                <TabPanel>
                  <Button mt="4" onClick={() => toggleHelpContent('webflow')}>
                    {helpVisible.webflow ? 'Hide Instructions' : 'Show Instructions'}
                  </Button>
                  {helpVisible.webflow && (
                    <Text whiteSpace="pre-wrap" mt="4">
                      {getHelpContent('webflow')}
                    </Text>
                  )}
                  <Flex width="100%" justify="center" direction="column" marginTop="20px" gap="20px">
                    <Flex direction="column">
                      <Text color="#505050" width="100%" as="b">
                        Webflow Domain
                      </Text>
                      <InputGroup>
                        <InputLeftAddon>https://</InputLeftAddon>
                        <Input
                          placeholder="your-webflow-site.com"
                          value={newConnection.url}
                          onChange={(e) => newConnectionHandleChange({ url: e.target.value, platform: 'webflow' })}
                        />
                      </InputGroup>
                    </Flex>
                    <Flex direction="column">
                      <Text color="#505050" width="100%" as="b">
                        Collection ID
                      </Text>
                      <Input
                        value={newConnection.username}
                        onChange={(e) => newConnectionHandleChange({ username: e.target.value })}
                      />
                    </Flex>
                    <Flex direction="column">
                      <Text color="#505050" width="100%" as="b">
                        Webflow API Key
                      </Text>
                      <Input
                        value={newConnection.password}
                        onChange={(e) => newConnectionHandleChange({ password: e.target.value })}
                      />
                    </Flex>
                  </Flex>
                </TabPanel>

                <TabPanel>
                  <Button mt="4" onClick={() => toggleHelpContent('shopify')}>
                    {helpVisible.shopify ? 'Hide Instructions' : 'Show Instructions'}
                  </Button>
                  {helpVisible.shopify && (
                    <Text whiteSpace="pre-wrap" mt="4">
                      {getHelpContent('shopify')}
                    </Text>
                  )}
                  <Flex width="100%" justify="center" direction="column" marginTop="20px" gap="20px">
                    <Flex direction="column">
                      <Text color="#505050" width="100%" as="b">
                        Store Name
                      </Text>
                      <Input
                        placeholder="your-shopify-store-name"
                        value={newConnection.url}
                        onChange={(e) => newConnectionHandleChange({ url: e.target.value, platform: 'shopify' })}
                      />
                    </Flex>
                    <Flex direction="column">
                      <Text color="#505050" width="100%" as="b">
                        Blog ID
                      </Text>
                      <Input
                        value={newConnection.username}
                        onChange={(e) => newConnectionHandleChange({ username: e.target.value })}
                      />
                    </Flex>
                    <Flex direction="column">
                      <Text color="#505050" width="100%" as="b">
                        Shopify Access Token
                      </Text>
                      <Input
                        value={newConnection.password}
                        onChange={(e) => newConnectionHandleChange({ password: e.target.value })}
                      />
                    </Flex>
                  </Flex>
                </TabPanel>

                <TabPanel>
                  <Button mt="4" onClick={() => toggleHelpContent('ghost')}>
                    {helpVisible.ghost ? 'Hide Instructions' : 'Show Instructions'}
                  </Button>
                  {helpVisible.ghost && (
                    <Text whiteSpace="pre-wrap" mt="4">
                      {getHelpContent('ghost')}
                    </Text>
                  )}
                  <Flex width="100%" justify="center" direction="column" marginTop="20px" gap="20px">
                    <Flex direction="column">
                      <Text color="#505050" width="100%" as="b">
                        Ghost Admin Domain
                      </Text>
                      <InputGroup>
                        <InputLeftAddon>https://</InputLeftAddon>
                        <Input
                          placeholder="your-ghost-admin-domain.com"
                          value={newConnection.url}
                          onChange={(e) => newConnectionHandleChange({ url: e.target.value, platform: 'ghost' })}
                        />
                      </InputGroup>
                    </Flex>
                    <Flex direction="column">
                      <Text color="#505050" width="100%" as="b">
                        Admin API Key
                      </Text>
                      <Input
                        value={newConnection.password}
                        onChange={(e) => newConnectionHandleChange({ password: e.target.value })}
                      />
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : (
            <>
              <VStack maxHeight="410px" overflowY="auto" spacing={3} align="stretch">
                {connections.map((connection) => {
                  return (
                    <HStack key={connection._id} borderWidth="1px" borderRadius="md" padding={3} width="100%">
                      <Image
                        src={getImageSrc(connection.platform)}
                        alt="Logo"
                        boxSize="40px"
                        objectFit="cover"
                        mr={1}
                      />
                      <Text>{connection.url.replace('https://', '').replace('http://', '').replace('www.', '')}</Text>
                      <Spacer />
                      <Flex width="200px" justify="right" pl="20px" gap="10px">
                        <Button
                          onClick={() => publish(connection._id)}
                          variant="solid"
                          colorScheme="green"
                          pr={8}
                          leftIcon={publishingDomainId === connection._id ? <Spinner size="sm" /> : <ExternalLinkIcon />}
                          isDisabled={publishingDomainId === connection._id}
                        >
                          {publishingDomainId === connection._id ? 'Publish' : 'Publish'}
                        </Button>
                        <IconButton
                          icon={deletingConnection === connection._id ? <Spinner size="sm" /> : <DeleteIcon />}
                          onClick={() => handleDeleteConnection(connection._id)}
                          isDisabled={deletingConnection === connection._id}
                        />
                      </Flex>
                    </HStack>
                  );
                })}
              </VStack>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {isAdding ? (
            <>
              <Button
                leftIcon={addingConnection ? <Spinner size="sm" /> : <AddIcon />}
                isDisabled={!isFormComplete() || addingConnection}
                colorScheme="blue"
                mr={3}
                onClick={handleAddConnection}
              >
                Add Connection
              </Button>
              <Button onClick={() => { setIsAdding(false); resetNewConnection(); }}>Cancel</Button>
            </>
          ) : (
            <Button leftIcon={<AddIcon />} colorScheme="blue" onClick={() => setIsAdding(true)}>
              Add Connection
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PublishManager