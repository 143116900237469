import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setModel } from '../store/generateOptionsSlice';
import {
    useDisclosure,
    Button,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ModalContent,
    Badge,
    Text
} from '@chakra-ui/react';
import parse from 'html-react-parser';

function AgentDetail({ agent }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const Chat = () => {
        if (agent.abilities.includes('Chat')) {
            return (
                <Button
                    variant='solid'
                    colorScheme='blue'
                    mr='15px'
                    w='90px'
                    size='sm'
                    ml='15px'
                    onClick={() => { navigate('/generate/chat') }}
                >
                    Chat
                </Button>
            )
        }
    };

    const renderedAbilities = agent.abilities.map(ability => {
        return <Badge variant='solid' mt='20px' mr='15px' key={ability}>{ability}</Badge>
    });

    let generateLink = `/agents/${agent.path}`;

    return (
        <>
            <Button
                onClick={onOpen}
                variant='outline'
                colorScheme='blue'
                w='90px'
                size='sm'
            >
                Details
            </Button>
            <Modal
                isCentered
                onClose={onClose}
                isOpen={isOpen}
                motionPreset='slideInBottom'
                size='xl'
            >
                <ModalOverlay />
                <ModalContent minW={'890px'}>
                    <ModalHeader>{agent.title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text sx={{ whiteSpace: 'pre-line', userSelect: 'none' }} lineHeight="taller">
                            {parse(agent.longDescription)}
                        </Text>
                        <br />
                        {renderedAbilities}
                    </ModalBody>
                    <ModalFooter justifyContent='right' margin='15px'>
                        <Button colorScheme='blue' size={'sm'} ml={3} w='90px' onClick={() => { navigate(generateLink) }}>
                            Generate
                        </Button>
                        <Chat />
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AgentDetail;