import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateVeoWriterOptions, updateActiveTasks, updateVeoWritercontent, updateIsLoadingVEOWriter, toggleLoading, updateVeoWriterActions, updateVeoWriterActionsLoading } from '../../store/contentSlice';
import { updateCredits } from '../../store/userSlice';
import noUser from '../../utils/HandleNoUser';
import GenerateVeoWriter from '../../promptApi/agents/VeoWriter';
import SelectDomain from'../../components/domains/selectDomain';
import SelectTOV from'../../components/TOV/SelectTOV';
import SelectSettings from './SelectSettings';
import SaveSettings from './SaveSettings';

import {
  Button,
  Textarea,
  Flex,
  FormControl,
  Select,
  Tooltip,
  Text,
  Heading,
  Switch,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  ButtonGroup
} from '@chakra-ui/react';

import { BsFillInfoCircleFill } from "react-icons/bs";
import { MdGraphicEq, MdOutlineClear } from "react-icons/md";

const ENV = process.env.NODE_ENV;
const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai';

const isValidUrl = (urlString) => {
  const pattern = new RegExp(
    `^(https?:\\/\\/)?` + // protocol
    `(([\\w\\d]([\\w\\d-]*[\\w\\d])*)\\.)+` + // domain name including subdomains
    `([a-z]{2,})` + // TLD, at least two characters
    `(\\:\\d+)?` + // port
    `(\\/[-a-z\\d%_.~+]*)*` + // path
    `(\\?[;&a-z\\d%_.~+=-]*)?` + // query string
    `(\\#[-a-z\\d_]*)?$`, // fragment locator
    'i'
  );
  return pattern.test(urlString);
};

const Model = ({model, handleChange}) => {
  const [selectedMethod, setSelectedMethod] = useState(model);

  useEffect(() => {
    setSelectedMethod(model);
  }, [model]);

  const toolTipLabel = 'Select the base model you want to write your content, experiment with different models and settings to get best results.';
  const buttonStyles = {
    default: {
      w: '130px',
      fontSize: 'sm', // Adjust font size here
      _hover: { bg: 'gray.100' }, // No background change on hover for default buttons
    },
    selected: {
      w: '130px',
      fontSize: 'sm', // Adjust font size here
      bgColor: 'gray.600',
      color: 'white',
      _hover: { bg: 'gray.600' }, // Retain the selected color on hover
    },
  };

  const handleSelect = (selection) => {
    setSelectedMethod(selection.value);
    handleChange({key: 'model', value: selection.value});
  };

  return (
    <Flex direction={'column'} gap={'10px'}>
      <Flex align={'center'}>
        <Heading size='sm' as='h1'>Model</Heading>
        <Tooltip label={toolTipLabel}>
          <Flex pl={'5px'}>
            <BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }} />
          </Flex>
        </Tooltip>
      </Flex>
      <Flex w='100%' gap='10px'>
      <Button minW='160px'
          {...(selectedMethod === 'GPT-4o-Mini' ? buttonStyles.selected : buttonStyles.default)}
          onClick={() => handleSelect({key: 'model', value: 'GPT-4o-Mini'})}
        >
          GPT-4o Mini
        </Button>
        <Button minW='160px'
          {...(selectedMethod === 'GPT-4o' ? buttonStyles.selected : buttonStyles.default)}
          onClick={() => handleSelect({key: 'model', value: 'GPT-4o'})}
        >
          GPT-4o
        </Button>
        <Button minW='160px'
          {...(selectedMethod === 'Claude-3.5-Sonnet' ? buttonStyles.selected : buttonStyles.default)}
          onClick={() => handleSelect({key: 'model', value: 'Claude-3.5-Sonnet'})}
        >
          Claude 3.5 Sonnet
        </Button>
      </Flex>
    </Flex>
  );
};

const TargetKeyword = ({keyword, handleChange}) => {
  const targetKeywordRef = useRef(null); 
  const targetKeywordTT = 'Enter the main keyword you want to rank for. The agent will then analyze pages ranking for this keyword, build out real-time knowledge a list of long-tail Latent Semantic Keywords and design an article structure with the maximum ranking potential.'
  
  useEffect(() => {
    // If there's logic that determines whether the settings have been updated,
    // include that here, and then focus the targetKeyword input if the condition is met.
      targetKeywordRef.current.focus();
    
  }, []); // Add dependencies here, like promptSettings, or other relevant state

  return (
      <Flex direction='column' gap={'10px'}>
        <Flex align={'center'}>
            <Heading size='sm' as='h1'>Target Keyword</Heading>
            <Tooltip label={targetKeywordTT}><Flex pl={'5px'}><BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }}/></Flex></Tooltip>
        </Flex>
        <Textarea
          ref={targetKeywordRef}
          data-key='keywords'
          value={keyword}
          onChange={(e)=>{handleChange({key: e.target.id, value: e.target.value})}}
          placeholder='Enter your primary keyword.'
          size='sm'
          w='100%'
          minH="40px"
          id={'targetKeyword'}
          autoFocus
        />
    </Flex>
  );
};

const KwMethod = ({ kwMethod, kwList, handleChange }) => {
  const [localKwMethod, setLocalKwMethod] = useState(kwMethod);
  const [localKwList, setLocalKwList] = useState(kwList);

  // Sync local state with props
  useEffect(() => {
    setLocalKwList(kwList);
  }, [kwList]);

  useEffect(() => {
    setLocalKwMethod(kwMethod);
  }, [kwMethod]);

  const buttonStyles = {
    default: {
      w: '130px',
      fontSize: 'sm', // Adjust font size here
      _hover: { bg: 'gray.100' }, // No background change on hover for default buttons
    },
    selected: {
      w: '130px',
      fontSize: 'sm', // Adjust font size here
      bgColor: 'gray.600',
      color: 'white',
      _hover: { bg: 'gray.600' }, // Retain the selected color on hover
    },
  };

  const kwMethodTT = (
    <>
      <Text>
        <strong>AI-Powered:</strong> The model you have chosen will use its training to expand on your target keyword and identify Latent Semantic keywords associated with your target. This improves ranking performance and content authority.
      </Text>
      <br/>
      <Text>
        <strong>SERP Analysis:</strong> We will analyze top-ranking pages from Google's search engine results page (SERP) and extract up to 100 topically relevant keywords and topical themes. This results in much comprehensive content that has a better chance of ranking for your target keyword.
      </Text>
      <br/>
      <Text>
        <strong>Manual:</strong> Specify a list of LSI keywords to be referenced within the content.
      </Text>
    </>
  );

  const handleKwMethodChange = (method) => {
    setLocalKwMethod(method);
    handleChange({ key: 'kwMethod', value: method });
  };

  const handleKwListChange = (e) => {
    const value = e.target.value;
    setLocalKwList(value);
    handleChange({ key: 'kwList', value: value });
  };

  return (
    <Flex direction={'column'} gap={'10px'}>
      <Flex align={'center'}>
        <Heading size='sm' as='h1'>Latent Semantic Indexing (LSI) Keyword Expansion</Heading>
        <Tooltip label={kwMethodTT}>
          <Flex pl={'5px'}>
            <BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }} />
          </Flex>
        </Tooltip>
      </Flex>
      <Flex w='100%' gap='10px'>
        <Button 
          minW='160px'
          {...(localKwMethod === 'AI-Powered' ? buttonStyles.selected : buttonStyles.default)}
          onClick={() => handleKwMethodChange('AI-Powered')}
        >
          AI-Powered
        </Button>
        
        <Button 
          minW='160px'
          {...(localKwMethod === 'SERP Analysis' ? buttonStyles.selected : buttonStyles.default)}
          onClick={() => handleKwMethodChange('SERP Analysis')}
        >
          SERP Analysis
        </Button>

        <Button 
          minW='160px'
          {...(localKwMethod === 'manual' ? buttonStyles.selected : buttonStyles.default)}
          onClick={() => handleKwMethodChange('manual')}
        >
          Manual
        </Button>
      </Flex>
      {localKwMethod === 'manual' && (
        <Textarea
          data-key='kwList'
          value={localKwList}
          onChange={handleKwListChange}
          placeholder='Enter your keyword list, separating each keyword with a comma or new line.'
          size='sm'
          mt='15px'
          w='100%'
          minH="120px"
          id={'kwList'}
        />
      )}
    </Flex>
  );
};

function Length({ length, handleChange }) {
  const [sliderValue, setSliderValue] = useState(length);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setSliderValue(length);
  }, [length]);

  const toolTipLabel = 'Usually a section will have around 500 words, so an article with 7 sections will be around 3,500 words in length.';
  let sliderLabel = 'Sections';
  const sectionWordCount = {
    1: '400 - 600 Words',
    2: '900 - 1,100 Words',
    3: '1,400 - 1,600 Words',
    4: '1,900 - 2,100 Words',
    5: '2,400 - 2,600 Words',
    6: '2,900 - 3,100 Words',
    7: '3,400 - 3,600 Words',
    8: '3,900 - 4,100 Words',
    9: '4,400 - 4,600 Words',
    10: '4,900 - 5,100 Words',
  };

  if (sliderValue === 1) {
    sliderLabel = 'Section';
  }

  return (
    <Flex direction={'column'} gap={'10px'}>
      <Flex align={'left'} direction={'column'} gap={5}>
        <Flex align={'center'}>
          <Heading size='sm' as='h1'>Number of sections / article length</Heading>
          <Tooltip label={toolTipLabel}>
            <Flex pl={'5px'}>
              <BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }} />
            </Flex>
          </Tooltip>
        </Flex>
        <Text fontSize={'sm'}>{sliderValue} {sliderLabel} Is around {sectionWordCount[sliderValue]}:</Text>
      </Flex>
      <Flex pt={'20px'} pb={'50px'} pl={'15px'} pr={'15px'}>
        <Slider
          focusThumbOnChange={false}
          id='slider'
          value={sliderValue}
          min={1}
          max={10}
          colorScheme='blue'
          onChange={(v) => { setSliderValue(v); handleChange({ key: 'length', value: v }); }}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {Object.keys(sectionWordCount).map((value) => (
            <SliderMark key={value} value={parseInt(value)} mt='8' ml={'-4px'} fontSize={value % 5 === 0 ? 'md' : 'xs'} color={value % 5 !== 0 ? 'gray.400' : null}>
              {value % 5 === 0 ? value : '|'}
            </SliderMark>
          ))}
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <Tooltip
            hasArrow
            bg='blue.500'
            color='white'
            placement='bottom'
            isOpen={showTooltip}
            label={`${sliderValue} ${sliderLabel}`}
          >
            <SliderThumb boxSize={10}>
              <Box color='blue' as={MdGraphicEq} />
            </SliderThumb>
          </Tooltip>
        </Slider>
      </Flex>
    </Flex>
  );
}

const ToneOfVoice = ({ TOV, handleChange }) => {
  const toolTipLabel = 'Choose from a pre-trained tone of voice, or use a custom tone of voice trained on your own data.';

  const [selectedOption, setSelectedOption] = useState(TOV);

  useEffect(() => {
    setSelectedOption(TOV);
  }, [TOV]);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    handleChange({ key: 'TOV', value: selectedValue });
  };

  return (
    <Flex direction={'column'} gap={'10px'} w={'100%'}>
      <Flex align={'center'}>
        <Heading size='sm' as='h1'>Tone of Voice</Heading>
        <Tooltip label={toolTipLabel}><Flex pl={'5px'}><BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }} /></Flex></Tooltip>
      </Flex>
      <Flex w='100%' gap='10px' mb={-3}>
        <SelectTOV selectedOption={selectedOption} handleSelectChange={handleSelectChange} />
      </Flex>
    </Flex>
  );
};

const Language = ({ language, handleChange }) => {
  const toolTipLabel = 'Choose from over 30 languages, when targeting non-english we recommend testing more than one model to get the best output quality.';

  const [selectedLanguage, setSelectedLanguage] = useState(language);

  useEffect(() => {
    setSelectedLanguage(language);
  }, [language]);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedLanguage(selectedValue);
    handleChange({ key: 'language', value: selectedValue });
  };

  return (
    <Flex direction={'column'} gap={'10px'} w={'100%'}>
      <Flex align={'center'}>
        <Heading size='sm' as='h1'>Language:</Heading>
        <Tooltip label={toolTipLabel}>
          <Flex pl={'5px'}>
            <BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }} />
          </Flex>
        </Tooltip>
      </Flex>
      <Select value={selectedLanguage} data-key='size' onChange={handleSelectChange}>
        <option value='english'>English</option>
        <option value='spanish'>Spanish</option>
        <option value='german'>German</option>
        <option value='french'>French</option>
        <option value='portuguese'>Portuguese</option>
        <option value='polish'>Polish</option>
        <option value='swedish'>Swedish</option>
        <option value='dutch'>Dutch</option>
        <option value='italian'>Italian</option>
        <option value='afrikaans'>Afrikaans</option>
        <option value='indonesian'>Indonesian</option>
        <option value='russian'>Russian</option>
        <option value='ukrainian'>Ukrainian</option>
        <option value='greek'>Greek</option>
        <option value='latvian'>Latvian</option>
        <option value='mandarin'>Mandarin</option>
        <option value='arabic'>Arabic</option>
        <option value='turkish'>Turkish</option>
        <option value='japanese'>Japanese</option>
        <option value='swahili'>Swahili</option>
        <option value='welsh'>Welsh</option>
        <option value='korean'>Korean</option>
        <option value='icelandic'>Icelandic</option>
        <option value='bengali'>Bengali</option>
        <option value='urdu'>Urdu</option>
        <option value='nepali'>Nepali</option>
        <option value='thai'>Thai</option>
        <option value='punjabi'>Punjabi</option>
        <option value='marathi'>Marathi</option>
        <option value='telugu'>Telugu</option>
      </Select>
    </Flex>
  );
};

const TargetCountry = ({ targetCountry, handleChange }) => {
  const toolTipLabel = 'Choose from over 30 target countries, this allows the agent to analyze the correct data for your target region and customise writting style for your geographic audience.';

  const [selectedCountry, setSelectedCountry] = useState(targetCountry);

  useEffect(() => {
    setSelectedCountry(targetCountry);
  }, [targetCountry]);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCountry(selectedValue);
    handleChange({ key: 'targetCountry', value: selectedValue });
  };

  return (
    <Flex direction={'column'} gap={'10px'} w={'100%'}>
      <Flex align={'center'}>
          <Heading size='sm' as='h1'>Target Country:</Heading>
          <Tooltip label={toolTipLabel}><Flex pl={'5px'}><BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }} /></Flex></Tooltip>
      </Flex>
      <Select value={selectedCountry} data-key='size' onChange={handleSelectChange}>
          <option value='USA'>USA</option>
          <option value='UK'>UK</option>
          <option value='canada'>Canada</option>
          <option value='ireland'>Ireland</option>
          <option value='australia'>Australia</option>
          <option value='new zealand'>New-Zealand</option>
          <option value='spain'>Spain</option>
          <option value='brazil'>Brazil</option>
          <option value='peru'>Peru</option>
          <option value='mexico'>Mexico</option>
          <option value='germany'>Germany</option>
          <option value='france'>France</option>
          <option value='portugal'>Portugal</option>
          <option value='poland'>Poland</option>
          <option value='sweden'>Sweden</option>
          <option value='netherlands'>Netherlands</option>
          <option value='italy'>Italy</option>
          <option value='south africa'>South Africa</option>
          <option value='indonesia'>Indonesia</option>
          <option value='russia'>Russia</option>
          <option value='ukraine'>Ukraine</option>
          <option value='israel'>Israel</option>
          <option value='greece'>Greece</option>
          <option value='latvia'>Latvia</option>
          <option value='Saudi Arabia'>Saudi Arabia</option>
          <option value='turkey'>Turkey</option>
          <option value='japan'>Japan</option>
          <option value='korea'>Korea</option>
          <option value='iceland'>Iceland</option>
          <option value='india'>India</option>
          <option value='pakistan'>Pakistan</option>
          <option value='nepal'>Nepal</option>
          <option value='thailand'>Thailand</option>
      </Select>
    </Flex>
  )
};

const PointOfView = ({pov, handleChange}) => {
  const toolTipLabel = 'Experiment with different models to get different results.'

  const handleSelectChange = (e) => {
    // Use the event object to get the selected option value
    const selectedValue = e.target.value;
    // Pass the selected value to the handleChange callback
    handleChange({ key: 'pov', value: selectedValue });
  };

  return (
      <Flex direction={'column'} gap={'10px'}>
          <Flex align={'center'}>
              <Heading size='sm' as='h1'>Point of view:</Heading>
              <Tooltip label={toolTipLabel}><Flex pl={'5px'}><BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }}  /></Flex></Tooltip>
          </Flex>
          <Select defaultValue={'Second Person (You, Your, Yours)'} data-key='size' onChange={handleSelectChange}>
              <option value='First Person Singular (I, Me, My, Mine)'>First Person Singular (I, Me, My, Mine)</option>
              <option value='First Person Plural (We, Us, Our, Ours)'>First Person Plural (We, Us, Our, Ours)</option>
              <option value='Second Person (You, Your, Yours)'>Second Person (You, Your, Yours)</option>
              <option value='Third Person (He, She, It, They)'>Third Person (He, She, It, They)</option>
          </Select>
      </Flex>
  )
}

const RealTime = ({useRealTimeSearch, handleChange}) => {
  return (
    <Flex direction={'column'} gap={'10px'}>
      <FormControl display='flex' alignItems='center' mt='4' gap={'10px'}>
        {/* Set the size to "lg" for a larger switch */}
        <Switch id='real-time-search' size='lg' colorScheme='blue' isChecked={useRealTimeSearch} onChange={()=>{handleChange({key: 'useRealTimeSearch', value: !useRealTimeSearch})}} />
        <Flex direction={'column'} pl={'15px'}>
          <Heading as='h1' size='sm' mb='2'>Use Real-Time Search Results</Heading>
          <Text fontSize='sm'>
          Enabling this feature allows the agent to analyze top-ranking pages for your target keyword and country, assessing ranking factors, content structure, writing styles, and current information. This results in a more comprehensive and updated article, boosting ranking potential and enhancing reader experience.          </Text>
        </Flex>
      </FormControl>
    </Flex>
  );
};

const ExternalLinks = ({externalLinks, handleChange}) => {
  return (
    <Flex direction={'column'} gap={'10px'}>
      <FormControl display='flex' alignItems='center' mt='4' gap={'10px'}>
        {/* Set the size to "lg" for a larger switch */}
        <Switch id='real-time-search' size='lg' colorScheme='blue' isChecked={externalLinks} onChange={()=>{handleChange({key: 'externalLinks', value: !externalLinks})}} />
        <Flex direction={'column'} pl={'15px'}>
          <Heading as='h1' size='sm' mb='2'>Add external links</Heading>
          <Text fontSize='sm'>
            If enabled, we will link out to approriate external resources, links are set to no-follow by default.
          </Text>
        </Flex>
      </FormControl>
    </Flex>
  );
};

const InternalLinks = ({internalLinks, domain, handleChange}) => {
  const [localDomain, setLocalDomain] = useState(domain);

  useEffect(() => {
    setLocalDomain(domain);
  }, [domain]);

  let setDomain = (domain) => {
    handleChange({key: 'domain', value: domain});
    setLocalDomain(domain);
  };
  
  return (
    <Flex direction={'column'} gap={'10px'} w='100%' mb={-3} p={'0px'}>
      <FormControl display='flex' alignItems='center' mt='4' gap={'10px'}>
        {/* Set the size to "lg" for a larger switch */}
        <Switch id='internalLinks' size='lg' colorScheme='blue' isChecked={internalLinks} onChange={()=>{handleChange({key: 'internalLinks', value: !internalLinks})}} />
        <Flex direction={'column'} pl={'15px'} w={'100%'}>
          <Heading as='h1' size='sm' mb='2'>Add internal links</Heading>
          <Text fontSize='sm'>
            If enabled, the AI will automatically add SEO optimised internall links for your selected domain.
          </Text>
        </Flex>
      </FormControl>
        {internalLinks && (<SelectDomain setDomain={setDomain} domain={localDomain}/>)}
    </Flex>
  );
};

const EmbedYoutubeVideo = ({embedYoutubeVideo, handleChange}) => {
  return (
    <Flex direction={'column'} gap={'10px'}>
      <FormControl display='flex' alignItems='center' mt='4' gap={'10px'}>
        {/* Set the size to "lg" for a larger switch */}
        <Switch id='embedYoutubeVideo' size='lg' colorScheme='blue' isChecked={embedYoutubeVideo} onChange={()=>{handleChange({key: 'embedYoutubeVideo', value: !embedYoutubeVideo})}} />
        <Flex direction={'column'} pl={'15px'}>
          <Heading as='h1' size='sm' mb='2'>Add youtube videos</Heading>
          <Text fontSize='sm'>
            If enabled, the agent will search Youtube and embed relevant videos into your content to enhance the reader experience.
          </Text>
        </Flex>
      </FormControl>
    </Flex>
  );
};

const OutlineEditor = ({useOutlineEditor, handleChange}) => {
  // Mock state functions for isChecked and onChange, replace them with your actual state logic

  return (
    <Flex direction={'column'} gap={'10px'}>
      <FormControl display='flex' alignItems='center' mt='4' gap={'10px'}>
        {/* Set the size to "lg" for a larger switch */}
        <Switch id='real-time-search' size='lg' colorScheme='blue' isChecked={useOutlineEditor} onChange={()=>{handleChange({key: 'useOutlineEditor', value: !useOutlineEditor})}} />
        <Flex direction={'column'} pl={'15px'}>
          <Heading as='h1' size='sm' mb='2'>Use Outline Editor</Heading>
          <Text fontSize='sm'>
          If enabled, an outline will first be created before your article is written. You can re-order, delete, and add new sections to the outline before generating the article!
          </Text>
        </Flex>
      </FormControl>
    </Flex>
  );
};

const FaqSection = ({includeFAQ, handleChange}) => {
  return (
    <Flex direction={'column'} gap={'10px'}>
      <FormControl display='flex' alignItems='center' mt='4' gap={'10px'}>
        {/* Set the size to "lg" for a larger switch */}
        <Switch id='includeFAQ' size='lg' colorScheme='blue' isChecked={includeFAQ} onChange={()=>{handleChange({key: 'includeFAQ', value: !includeFAQ})}} />
        <Flex direction={'column'} pl={'15px'}>
          <Heading as='h1' size='sm' mb='2'>Include FAQ Section</Heading>
          <Text fontSize='sm'>
          If enabled, the agent will add a FAQ section based on SERP analysis. This will increase article length, and may help you rank for snippets.
          </Text>
        </Flex>
      </FormControl>
    </Flex>
  );
};

const KeyTakeaways = ({includeKeyTakeaways, handleChange}) => {
  return (
    <Flex direction={'column'} gap={'10px'}>
      <FormControl display='flex' alignItems='center' mt='4' gap={'10px'}>
        {/* Set the size to "lg" for a larger switch */}
        <Switch id='real-time-search' size='lg' colorScheme='blue' isChecked={includeKeyTakeaways} onChange={()=>{handleChange({key: 'includeKeyTakeaways', value: !includeKeyTakeaways})}} />
        <Flex direction={'column'} pl={'15px'}>
          <Heading as='h1' size='sm' mb='2'>Include Key Takeaways</Heading>
          <Text fontSize='sm'>
          If enabled, the agent will include a summary of key takeaways beneth the article introduction.
          </Text>
        </Flex>
      </FormControl>
    </Flex>
  );
};

const URLs = ({urls, handleChange}) => {
  // Mock state functions for isChecked and onChange, replace them with your actual state logic
  const toolTipLabel = 'Experiment with different models to get different results.'


  return (
      <Flex direction='column'  gap={'10px'}>
        <Flex align={'center'}>
            <Text size='sm' as='h1'>URLs to link to:</Text>
            <Tooltip label={toolTipLabel}><Flex pl={'5px'}><BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }}/></Flex></Tooltip>
        </Flex>
        <Textarea
        data-key='keywords'
        value={urls}
        onChange={(e)=>{handleChange({key: 'urls', value: e.target.value})}}
        placeholder='Place your negative prompt here.'
        size='sm'
        w='100%'
        minH="80px"
        id={'negative-prompt'}
        />
    </Flex>
  );
};

const CustomOuline = ({customOutline, handleChange}) => {
  const toolTipLabel = 'Experiment with different models to get different results.'

  return (
      <Flex direction='column'  gap={'10px'}>
        <Flex align={'center'}>
            <Text size='sm' as='h1'>Custom outline:</Text>
            <Tooltip label={toolTipLabel}><Flex pl={'5px'}><BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }}/></Flex></Tooltip>
        </Flex>
        <Textarea
        data-key='keywords'
        value={customOutline}
        onChange={(e)=>{handleChange({key: 'customOutline', value: e.target.value})}}
        placeholder='Place your negative prompt here.'
        size='sm'
        w='100%'
        minH="80px"
        id={'negative-prompt'}
        />
    </Flex>
  );
};

const TitleInstructions = ({titleInstructions, handleChange}) => {
  const toolTipLabel = 'Experiment with different models to get different results.'

  return (
      <Flex direction='column'  gap={'10px'}>
        <Flex align={'center'}>
            <Text size='sm' as='h1'>Title Instructions:</Text>
            <Tooltip label={toolTipLabel}><Flex pl={'5px'}><BsFillInfoCircleFill style={{ color: 'rgb(112,128,170)' }}/></Flex></Tooltip>
        </Flex>
        <Textarea
        data-key='titleInstruction'
        value={titleInstructions}
        onChange={(e)=>{handleChange({key: 'titleInstructions', value: e.target.value})}}
        placeholder='Place your negative prompt here.'
        size='sm'
        w='100%'
        minH="80px"
        id={'negative-prompt'}
        />
    </Flex>
  );
};

const DisableIntro = ({disableIntro, handleChange}) => {
  // Mock state functions for isChecked and onChange, replace them with your actual state logic
  const [isChecked, setIsChecked] = React.useState(false);
  const handleOnChange = () => setIsChecked(!isChecked);

  return (
    <Flex direction={'column'} gap={'10px'}>
      <FormControl display='flex' alignItems='center' mt='4' gap={'10px'}>
        {/* Set the size to "lg" for a larger switch */}
        <Switch id='real-time-search' size='lg' colorScheme='blue' isChecked={disableIntro} onChange={()=>{handleChange({key: 'disableIntro', value: !disableIntro})}} />
        <Flex direction={'column'} pl={'15px'}>
          <Heading as='h1' size='sm' mb='2'>Disable Introduction</Heading>
          <Text fontSize='sm'>
          By default the agent will include an introduction of two to three paragraphs. You can turn off introductions by enabling this setting.
          </Text>
        </Flex>
      </FormControl>
    </Flex>
  );
};

const DisableConclusion = ({disableConclusion, handleChange}) => {
  return (
    <Flex direction={'column'} gap={'10px'}>
      <FormControl display='flex' alignItems='center' mt='4' gap={'10px'}>
        {/* Set the size to "lg" for a larger switch */}
        <Switch id='real-time-search' size='lg' colorScheme='blue' isChecked={disableConclusion} onChange={()=>{handleChange({key: 'disableConclusion', value: !disableConclusion})}} />
        <Flex direction={'column'} pl={'15px'}>
          <Heading as='h1' size='sm' mb='2'>Disable Conclusion</Heading>
          <Text fontSize='sm'>
          By default the agent will include a closing paragraph as a conclusion. You can turn off introductions by enabling this setting.
          </Text>
        </Flex>
      </FormControl>
    </Flex>
  );
};

export default function VeoWriterSettings() {
  const isLoadingVEOWriter = useSelector((state) => state.content.isLoadingVEOWriter);
  const promptSettings = useSelector((state) => state.content.veoWriterOptions);

  const [buttonLoading, setButtonLoading] = useState({
    optimisePrompt: false,
    downloadFile: false
  });

  let initialSettings = {
    model: 'GPT-4o-Mini',
    targetKeyword: '',
    kwMethod: 'AI-Powered',
    kwList: '',
    length: 4,  
    TOV: 'SEO Optimised',
    language: 'English',
    targetCountry: 'USA',
    pov: 'Second Person (You, Your, Yours)',
    useRealTimeSearch: false,
    externalLinks: false,
    internalLinks: false,
    domain: '',
    embedYoutubeVideo: false,
    useOutlineEditor: false,
    includeFAQ: false,
    includeKeyTakeaways: false,
    urls: [],
    customOutline: [],
    TitleInstructions: '',
    disableIntro: false,
    disableConclusion: false
  };

  // useEffect(() => {
  //     dispatch(updateVeoWriterOptions(initialSettings));
  // }, []);

  const dispatch = useDispatch();

  const handleChange = selection => {
    let {key, value} = selection;
    let newSettings = {
      ...promptSettings,
      [key]: value
    }
    dispatch(updateVeoWriterOptions(newSettings));
  }

  const handleSubmit = async () => {
    const actionName = 'veo-writer' + Date.now();
    dispatch(updateActiveTasks({ operation: 'addOrUpdate', key: 'veowriter', value: actionName }));
    dispatch(updateVeoWriterActionsLoading(true));
    dispatch(updateVeoWriterActions({action: 'overwrite', 'content': []}));
    dispatch(updateIsLoadingVEOWriter(true));
    dispatch(updateVeoWritercontent({action: 'overwrite', 'content': ''}));

    try {
      const response = await GenerateVeoWriter( promptSettings, actionName);
      // ReadableStream processing
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';
      
      while (true) { // A while loop here is sufficient since you break it when done is true
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
  
        // Decode the current chunk and append it to the buffer
        buffer += decoder.decode(value, { stream: true });
        // Process complete messages in the buffer
        while (buffer.includes('\n\n')) {
          
          const splitIndex = buffer.indexOf('\n\n\n');
          const message = buffer.slice(0, splitIndex); // Extract the message up to the delimiter
          buffer = buffer.slice(splitIndex + 2); // Remove the processed message from the buffer
          if (message === '\nno user') {
            noUser();
          }else if(message === '\ninsufficient credit'){
            // set no credits
            dispatch(updateCredits(false));
          } else {
            try {
              let chunkObj = JSON.parse(message);
              if (chunkObj.event === 'content' && chunkObj.load.length > 0) {
                  setTimeout(()=>{
                    dispatch(updateVeoWriterActionsLoading(false));
                    dispatch(updateVeoWriterActions({action: 'overwrite', 'content': []}));
                  },15000);
                dispatch(updateVeoWritercontent({action: 'concat', 'content': chunkObj.load}));
              }
              if (chunkObj.event === 'system' && chunkObj.load.length > 0) {
                if(chunkObj.load.length > 0){
                  dispatch(updateVeoWriterActions({action: 'concat', 'content': chunkObj.load}));
                }
              }
            } catch (e) {
              //console.error('Error parsing JSON:', e, message);
            }
          }
        }
      }
    } catch (e) {
      console.error('Fetch failed', e);
    }
    dispatch(updateIsLoadingVEOWriter(false));
  };

  let resetSettings = () => {
    dispatch(updateVeoWriterOptions(initialSettings));
    dispatch(updateVeoWritercontent({ action: 'overwrite', 'content': '' }));

  }

  function isDeepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;
    
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      return false;
    }
    
    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);
    
    if (keys1.length !== keys2.length) return false;
    
    for (let key of keys1) {
      if (!keys2.includes(key) || !isDeepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  }
  
  let showSaveClear = isDeepEqual(initialSettings, promptSettings);
  let showGenerate = false;

  if(promptSettings.hasOwnProperty('targetKeyword')){
    if(promptSettings.targetKeyword.length > 0){
      showGenerate = true;
    }
  }

  const setPromptSettings = (newSettings)=>{dispatch(updateVeoWriterOptions(newSettings))};

  return (
    <Flex flex={1} align={'top'} justify={'center'} h={'100%'} m={'0px'}>
      <FormControl id="prompt">
        <Flex direction='column' m='0px'>
          <Flex direction='column' m='20px' gap={'50px'}>
            <Flex direction='column' gap='10px'>  
            <Flex w={'100%'} justify={'space-between'} mb={'30px'}>
              <Heading as='h1' size='xl' mb='2'>VeoWriter SEO</Heading>
              <SelectSettings loadSettings={setPromptSettings} />
            </Flex>

              <Text fontSize='md'>
                Enter your target keyword, the agent will analyze Google search results and research relevant real-time data sources before generating unique, SEO-optimized Google compliant content, in your brand voice.
              </Text>

            </Flex>

            <TargetKeyword keyword={promptSettings.targetKeyword} handleChange={handleChange}/>
            <Model model={promptSettings.model} handleChange={handleChange}/>
            <KwMethod kwMethod={promptSettings.kwMethod} kwList={promptSettings.kwList} handleChange={handleChange}/>
            <RealTime useRealTimeSearch={promptSettings.useRealTimeSearch} handleChange={handleChange}/>
            {promptSettings.useRealTimeSearch && <ExternalLinks externalLinks={promptSettings.externalLinks} handleChange={handleChange}/>}
            <Flex gap={'10px'} w='100%'>
              <Language language={promptSettings.language} handleChange={handleChange}/>
              <TargetCountry targetCountry={promptSettings.targetCountry} handleChange={handleChange}/>
            </Flex>
            <ToneOfVoice 
              TOV={promptSettings.TOV} 
              handleChange={handleChange}/>
            {/* <PointOfView pov={promptSettings.pov} handleChange={handleChange}/> */}
            {/* <TitleInstructions TitleInstructions={promptSettings.TitleInstructions} handleChange={handleChange}/> */}
            <Length length={promptSettings.length} handleChange={handleChange}/>
            <InternalLinks internalLinks={promptSettings.internalLinks} domain={promptSettings.domain} handleChange={handleChange}/>
            <EmbedYoutubeVideo embedYoutubeVideo={promptSettings.embedYoutubeVideo} handleChange={handleChange}/>
            {/* <OutlineEditor useOutlineEditor={promptSettings.useOutlineEditor} handleChange={handleChange}/> */}
            <FaqSection includeFAQ={promptSettings.includeFAQ} handleChange={handleChange}/>
            <KeyTakeaways includeKeyTakeaways={promptSettings.includeKeyTakeaways} handleChange={handleChange}/>
            {/* <URLs urls={promptSettings.urls} handleChange={handleChange}/> */}
            {/* <CustomOuline customOutline={promptSettings.customOutline} handleChange={handleChange}/> */}
            <DisableIntro disableIntro={promptSettings.disableIntro} handleChange={handleChange}/>
            <DisableConclusion disableConclusion={promptSettings.disableConclusion} handleChange={handleChange}/>

          </Flex>
          <Flex
            pl={'20px'}
            pr={'20px'}
            mb={'-60px'}
            pt={'40px'}
            pb={'20px'}
            position="sticky" 
            bottom={0}
            width={"100%"}
            justify="space-between" 
            gap="20px" 
            zIndex="1" // Ensures that it will overlay other content
            bg="linear-gradient(to top, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0.0) 100%)" // Transparent gradient from solid white to transparent
            >
            <Button w="100%" colorScheme="blue" variant="solid" borderColor="gray.400" onClick={handleSubmit} isDisabled={!showGenerate || isLoadingVEOWriter}>
              {'Generate Article'}
            </Button>
            
            <ButtonGroup isAttached variant="outline">
              <Button w="120px" colorScheme="blue" variant="outline" leftIcon={<MdOutlineClear/>} borderColor="gray.400" onClick={()=>{resetSettings()}} isDisabled={showSaveClear || isLoadingVEOWriter}>
                {'Clear'}
              </Button>
              <SaveSettings options={promptSettings} showImprovePrompt={showSaveClear}/>
            </ButtonGroup>      
          </Flex>
        </Flex>
      </FormControl>
    </Flex>
  );
}