import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Select,
  Flex,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  VStack,
  Text,
  HStack,
  Spacer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
  useToast,
  Heading,
  Textarea,
  Center,
} from "@chakra-ui/react";
import { DeleteIcon, AddIcon } from "@chakra-ui/icons";

const TOVManager = ({ selectedOption, TOVs, addTOV, handleSelectChange, fetchTOVs, hideSelect}) => {
  const [selectedTOV, setSelectedTOV] = useState(selectedOption);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tovName, setTovName] = useState("");
  const [xmlAddress, setXmlAddress] = useState("");
  const [exampleContent, setExampleContent] = useState("");
  const [guidelinesContent, setGuidelinesContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deletingTOV, setDeletingTOV] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // Track active tab
  const toast = useToast();

  useEffect(() => {
    setSelectedTOV(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    if (!isOpen) {
      clearInputs();
    }
  }, [isOpen]);

  const clearInputs = () => {
    setTovName("");
    setXmlAddress("");
    setExampleContent("");
    setGuidelinesContent("");
  };

  const isFormValid = () => {
    const isValidURL = xmlAddress.trim().length > 0;
    const isValidExample = exampleContent.split(/\s+/).filter(word => word.trim().length > 0).length >= 500 && exampleContent.split(/\s+/).filter(word => word.trim().length > 0).length <= 3000;
    const isValidGuidelines = guidelinesContent.split(/\s+/).filter(word => word.trim().length > 0).length >= 10 && guidelinesContent.split(/\s+/).filter(word => word.trim().length > 0).length <= 300;

    if (activeTab === 0) {
      return tovName.trim().length > 0 && isValidURL;
    } else if (activeTab === 1) {
      return tovName.trim().length > 0 && isValidExample;
    } else if (activeTab === 2) {
      return tovName.trim().length > 0 && isValidGuidelines;
    }
    return false;
  };

  const handleAddTOV = async () => {
    if (!isFormValid()) return;

    setIsLoading(true);
    let method;
    let data;

    if (activeTab === 0 && xmlAddress.trim()) {
      method = 'url';
      data = xmlAddress;
    } else if (activeTab === 1 && exampleContent.trim()) {
      method = 'sample';
      data = exampleContent;
    } else if (activeTab === 2 && guidelinesContent.trim()) {
      method = 'description';
      data = guidelinesContent;
    }

    const tovData = {
      name: tovName,
      data,
      method,
    };

    try {
      const response = await addTOV(tovData);

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'TOV added successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchTOVs();
        setIsAdding(false);
        clearInputs();
        onClose(); // Close the modal after successful submission
      } else {
        const error = await response.json();
        toast({
          title: 'Error',
          description: error.message || 'Something went wrong',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'Internal Server Error',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTOV = async (TOV) => {
    setDeletingTOV(TOV);
    try {
      const response = await fetch('/TOV/delete', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ _id: TOV }),
      });

      if (response.status === 200) {
        const result = await response.json();
        toast({
          title: 'Success',
          description: result.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchTOVs();
      } else if (response.status === 404) {
        const result = await response.json();
        toast({
          title: 'Error',
          description: result.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Error',
          description: 'Something went wrong',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Internal Server Error',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setDeletingTOV(null);
    }
  };

  return (
    <Box>
      <Flex alignItems="center" w='100%'>
        {!hideSelect && (
          <Select
            w='440px'
            placeholder={TOVs.length === 0 ? 'No TOVs available' : ''}
            value={selectedTOV}
            id={'TOV'}
            onChange={(e) => {
              setSelectedTOV(e.target.value);
              handleSelectChange(e);
            }}
            marginRight='20px'
            >
            {TOVs.map((TOV) => (
              <option key={TOV._id} value={TOV.name}>
                {TOV.name}
              </option>
            ))}
          </Select>
          )}
        {
          hideSelect ? (
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => {
                onOpen();
                if (TOVs.length > 6) {
                  setIsAdding(false);
                } else {
                  setIsAdding(true);
                }
              }}
            >
              Manage TOVs
            </Button>
          ) : (
            <Button
              onClick={() => {
                onOpen();
                if (TOVs.length > 6) {
                  setIsAdding(false);
                } else {
                  setIsAdding(true);
                }
              }}
              fontSize="sm" // Adjust font size here
              bgColor="blue.500"
              color="white"
              _hover="none"
              w="145px"
            >
              Manage TOVs
            </Button>
          )
        }
      </Flex>
      <TOVPopup
        isOpen={isOpen}
        onClose={onClose}
        TOVs={TOVs}
        deleteTOV={handleDeleteTOV}
        isAdding={isAdding}
        setIsAdding={setIsAdding}
        tovName={tovName}
        setTovName={setTovName}
        xmlAddress={xmlAddress}
        setXmlAddress={setXmlAddress}
        exampleContent={exampleContent}
        setExampleContent={setExampleContent}
        guidelinesContent={guidelinesContent}
        setGuidelinesContent={setGuidelinesContent}
        handleAddTOV={handleAddTOV}
        isLoading={isLoading}
        deletingTOV={deletingTOV}
        isFormValid={isFormValid}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </Box>
  );
};

const TOVPopup = ({
  isOpen,
  onClose,
  TOVs,
  deleteTOV,
  isAdding,
  setIsAdding,
  tovName,
  setTovName,
  xmlAddress,
  setXmlAddress,
  exampleContent,
  setExampleContent,
  guidelinesContent,
  setGuidelinesContent,
  handleAddTOV,
  isLoading,
  deletingTOV,
  isFormValid,
  activeTab,
  setActiveTab,
}) => {
  const exampleWordCount = exampleContent.split(/\s+/).filter(word => word.trim().length > 0).length;
  const guidelinesWordCount = guidelinesContent.split(/\s+/).filter(word => word.trim().length > 0).length;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent minHeight="500px">
        <ModalHeader>{isAdding ? "Create a New Custom TOV" : "Manage Custom TOVs"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Flex align="center" justify="center" height="100%" direction="column" gap="30px" marginTop="20px">
              <Heading>Training TOV...</Heading>
              <Text marginBottom="30px">This can take several minutes.</Text>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            </Flex>
          ) : isAdding ? (
            <Tabs variant="enclosed" index={activeTab} onChange={setActiveTab}>
              <TabList>
                <Tab>Provide URL</Tab>
                <Tab>Provide Example</Tab>
                <Tab>Provide Guidelines</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Flex width="100%" justify="left" direction="column" w={'100%'}>
                    <Flex width="100%" justify="left" direction="column" marginTop="10px" w={'100%'}>
                      <Text color="#505050" width="100%">
                        Give your custom TOV a descriptive name:
                      </Text>
                      <Input
                        placeholder="My casual blog writing style"
                        value={tovName}
                        onChange={(e) => setTovName(e.target.value)}
                        mt={3}
                      />
                    </Flex>
                    <Flex width="100%" justify="left" direction="column" marginTop="30px" w={'100%'}>
                      <Text color="#505050" width="100%">
                        Enter the full URL for your example content:
                      </Text>
                      <Text color="#505050" fontSize='xs' as='i'>
                        (Blog post, web article or webpage with written content)
                      </Text>
                      <Input
                        placeholder="https://www.TOV.com/blog/example-post"
                        value={xmlAddress}
                        onChange={(e) => setXmlAddress(e.target.value)}
                        mt={3}
                      />
                    </Flex>
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Flex width="100%" justify="left" direction="column" w={'100%'}>
                    <Flex width="100%" justify="left" direction="column" marginTop="10px" w={'100%'}>
                      <Text color="#505050" width="100%">
                        Give your custom TOV a descriptive name:
                      </Text>
                      <Input
                        placeholder="My casual blog writing style"
                        value={tovName}
                        onChange={(e) => setTovName(e.target.value)}
                        mt={3}
                      />
                    </Flex>
                    <Flex width="100%" justify="left" direction="column" marginTop="30px" w={'100%'}>
                      <Text color="#505050" width="100%">
                        Enter your example content:
                      </Text>
                      <Text color="#505050" fontSize='xs' as='i'>
                        (Provide 500 - 3,000 words)
                      </Text>
                      <Textarea
                        placeholder="Enter your example content here..."
                        value={exampleContent}
                        onChange={(e) => setExampleContent(e.target.value)}
                        mt={3}
                        size='lg' // Making the Textarea larger
                        height="200px" // Set a larger height
                      />
                      <Text mt={2} color={exampleWordCount >= 500 && exampleWordCount <= 3000 ? 'green.500' : 'red.500'}>
                        Word Count: {exampleWordCount}
                      </Text>
                    </Flex>
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Flex width="100%" justify="left" direction="column" w={'100%'}>
                    <Flex width="100%" justify="left" direction="column" marginTop="10px" w={'100%'}>
                      <Text color="#505050" width="100%">
                        Give your custom TOV a descriptive name:
                      </Text>
                      <Input
                        placeholder="My casual blog writing style"
                        value={tovName}
                        onChange={(e) => setTovName(e.target.value)}
                        mt={3}
                      />
                    </Flex>
                    <Flex width="100%" justify="left" direction="column" marginTop="30px" w={'100%'}>
                      <Text color="#505050" width="100%">
                        Enter your guidelines content:
                      </Text>
                      <Text color="#505050" fontSize='xs' as='i'>
                        (Provide 10 - 300 words)
                      </Text>
                      <Textarea
                        placeholder="Enter your guidelines here..."
                        value={guidelinesContent}
                        onChange={(e) => setGuidelinesContent(e.target.value)}
                        mt={3}
                        size='lg' // Making the Textarea larger
                        height="200px" // Set a larger height
                      />
                      <Text mt={2} color={guidelinesWordCount >= 10 && guidelinesWordCount <= 300 ? 'green.500' : 'red.500'}>
                        Word Count: {guidelinesWordCount}
                      </Text>
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : (
            <>
              {TOVs.length > 6 ? (
                <VStack
                  maxHeight="400px"
                  overflowY="auto"
                  spacing={3}
                  align="stretch"
                >
                  {TOVs.filter((TOV) => !['1', '2', '3', '4', '5', '6'].includes(TOV._id)).map((TOV) => (
                    <Flex key={TOV._id} direction={'row'} align={'center'} justify={'space-between'} borderWidth="1px" borderRadius="md" padding={3} width="100%" height='66px'>
                      <Text>{TOV.name}</Text>
                      <Flex width="90px" justify="right">
                        {deletingTOV === TOV._id ? (
                          <Spinner size="md" color="blue.500" marginRight={'8px'} />
                        ) : (
                          <IconButton
                            icon={<DeleteIcon />}
                            aria-label="Delete TOV"
                            onClick={() => deleteTOV(TOV._id)}
                          />
                        )}
                      </Flex>
                    </Flex>
                  ))}
                </VStack>
              ) : (
                <Text>You don't have any custom TOVs.</Text>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {isAdding ? (
            <>
              <Button colorScheme="blue" mr={3} onClick={handleAddTOV} isDisabled={!isFormValid()}>
                Start Training
              </Button>
              <Button onClick={() => setIsAdding(false)}>Cancel</Button>
            </>
          ) : (
            <Button leftIcon={<AddIcon />} colorScheme="blue" onClick={() => setIsAdding(true)}>
              New Custom TOV
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TOVManager;